import { Filters, FilterType } from "~/types/filters";

/**
 * Get list of selected filters
 * @param filters - The object containing all filter settings.
 */
export const useNewGetFiltersSelected = (filters: Filters) => {
  const selectedFilters = Object.entries(filters)
    .filter(([index, filter]) => filter.chosenOptions.length && filter.type !== FilterType.SEARCH)
    .map(([key, filter]) =>
      filter.chosenOptions.map((option) => ({
        key: key,
        name: option.name,
        value: option.value,
      }))
    )
    .flat();

  return selectedFilters;
};
